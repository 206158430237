import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './components/App'
import moment from 'moment-timezone'
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import './styles/index.css'

import { GRAPHQL_API_URL, GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE, GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE_EXPIRATION } from './constants'
import './window-broadwayRoulette.js'

import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'

// TODO 8BASE: cache credentials for performance?!
const client = new ApolloClient({
  uri: GRAPHQL_API_URL,
  
  request: (operation) => {
    var token = localStorage.getItem(GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE)
    var expiration = parseInt(localStorage.getItem(GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE_EXPIRATION), 10)
    var now = moment().unix()

    if (token && expiration < now) {
      token = ''
      window.localStorage.removeItem(GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE)
      window.localStorage.removeItem(GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE_EXPIRATION)
    }

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    })
  }
})

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>
  , document.getElementById('root')
)
unregisterServiceWorker()
