import React, { Component } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import moment from 'moment-timezone'
import queryString from 'query-string'

import Box from '@material-ui/core/Box'
import MenuIcon from '@material-ui/icons/Menu'

import { GA_ACTION, GA_EVENT, sendGAEvent } from '../analytics'
import { GRAPHQL_AUTH_CLIENT_ID, GRAPHQL_AUTH_DOMAIN, TODAY } from '../constants'

import LoginAuth0 from './LoginAuth0'

import './Header.css'

import logo from '../images/broadway-roulette-logo.png'

class Header extends Component {

  state = {
    mobileHeaderCollapsed: true,
    isNoteVisible: false
  }

  _isLoggedIn = () => {
    return !!this.props.user
  }

  _getShouldShowTicketsBanner = () => {
    var orders = this.props.user && this.props.user.orders.items
    var isOnSpinPage = this.props.location.pathname === '/buy-tickets'
    var showsToday = orders && orders.filter(order => order.requestedShowDate === TODAY && order.isValidOrder &&
                                                      order.orderStatus.sentTicketsEmailAt)

    var shouldShowBanner = showsToday && showsToday.length > 0 && !isOnSpinPage
    return shouldShowBanner
  }

  _getShouldShowHowBanner = () => {
    var source = queryString.parse(this.props.location.search).utm_source
    var isFromTodayTixSource = source === 'todaytix'
    var isFromTikTokSource = source === 'tiktok'
    var isOnSpinPage = this.props.location.pathname === '/buy-tickets'

    var shouldShowBanner = (isFromTodayTixSource || isFromTikTokSource) && isOnSpinPage
    return shouldShowBanner
  }

  _getShouldShowCyberBanner = (shouldShowTicketsBanner, shouldShowHowBanner) => {
    var hasActiveBanner = shouldShowTicketsBanner || shouldShowHowBanner
    var isBeforeEndDate = TODAY <= moment('2023-11-30').format('YYYY-MM-DD')
    var isOnHomePage = this.props.location.pathname === '/'

    var shouldShowBanner = !hasActiveBanner && isBeforeEndDate && isOnHomePage
    return shouldShowBanner
  }

  render() {
    var isLoggedIn = this._isLoggedIn()

    var shouldShowTicketsBanner = this._getShouldShowTicketsBanner()
    var shouldShowHowBanner = this._getShouldShowHowBanner()
    var shouldShowCyberBanner = this._getShouldShowCyberBanner(shouldShowTicketsBanner, shouldShowHowBanner)

    return (
      <span>

        {shouldShowTicketsBanner &&
          <Link to={'/account'}>
            <div className="tickets-banner">You've won! Click to see what show you're going to!</div>
          </Link>
        }

        {shouldShowHowBanner && !shouldShowCyberBanner &&
          <Link to={`/how-it-works${this.props.location.search}`}>
            <div className="tickets-banner">Click here to learn how it works!</div>
          </Link>
        }

        <Link to={'/gift-card'} className={shouldShowCyberBanner ? '' : 'hide-banner'}>
          <div className="tickets-banner">SAVE 10% ON GIFT CARDS</div>
        </Link>

        <div className={"header " + (this.state.mobileHeaderCollapsed ? 'collapsed ' : 'expanded ') + (window.location.pathname === '/1' ? 'landing-1-header' : '')}>
          <div className="container">

            <Link to={'/'} aria-label="logo home page link">
              <img alt='logo'
                   className='logo showmobile'
                   width='150px'
                   height='51px'
                   src={logo}/>
            </Link>
            <div className="header-open">
              <MenuIcon className="menu-icon" fontSize="large" onClick={() => { this.setState({ mobileHeaderCollapsed: false })}}/>
            </div>

            <div className="fade-content">
              <Box className="header-nav">

                <Box className="logo-nav">
                  <div>
                    <Link to={'/'} aria-label="logo home page link">
                      <img alt='logo'
                           className='logo hidemobile'
                           width='150px'
                           height='51px'
                           src={logo}/>
                    </Link>
                    <div className="header-close"
                         onClick={() => { this.setState({ mobileHeaderCollapsed: true })}}>
                      X
                    </div>
                  </div>
                </Box>

                <Box className="nav-item">
                  <Link to={'/how-it-works'}
                        className='no-link-style'
                        onClick={() => {
                          this.setState({ mobileHeaderCollapsed: true })
                          sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.HOW_IT_WORKS_HEADER)
                        }}>
                  How it Works
                  </Link>
                </Box>

                <Box className="nav-item">
                  <Link to={'/current-shows'}
                        className='no-link-style'
                        onClick={() => {
                          this.setState({ mobileHeaderCollapsed: true })
                        }}>
                  Shows
                  </Link>
                </Box>

                <Box className="nav-item">
                  <a href="https://theatreroulette.com"
                        className='no-link-style'
                        onClick={() => { this.setState({ mobileHeaderCollapsed: true })}}>
                  West End
                  </a>
                </Box>

                <Box className="nav-item">
                  <Link to={'/gift-card'}
                        className='no-link-style'
                        onClick={() => {
                          this.setState({ mobileHeaderCollapsed: true })
                        }}>
                  Gift Cards
                  </Link>
                </Box>

                <Box className="nav-item login">
                  {!isLoggedIn &&
                    <LoginAuth0 clientId={GRAPHQL_AUTH_CLIENT_ID}
                                domain={GRAPHQL_AUTH_DOMAIN}
                                primaryColor='#d50032'>
                      Log in
                    </LoginAuth0>
                  }

                  {isLoggedIn &&
                    <Link className='no-link-style'
                          to="/account">
                      Account
                    </Link>
                  }
                </Box>

                <Box className="nav-item">
                  <Link to={'/buy-tickets'}
                        className='no-link-style buy-tickets-button'
                        onClick={() => {
                          this.setState({ mobileHeaderCollapsed: true })
                          sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.BUY_TICKETS_HEADER)
                        }}>
                    BUY NOW
                  </Link>
                </Box>

              </Box>
            </div>
          </div>
        </div>
      </span>
    )
  }

}

export default Header
